import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import swal from 'sweetalert';

Modal.setAppElement('#root');

function Managecatop2({ apiEndpoint = "vitals", itemName = "vitals" }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newItemText, setNewItemText] = useState('');

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/${apiEndpoint}`);
      setItems(response.data);
    } catch (error) {
      console.error(`Error fetching ${itemName}:`, error);
      swal("Error!", `Failed to fetch ${itemName}.`, "error");
    }
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setNewItemText(item[itemName]);
    setModalIsOpen(true);
  };

  const handleUpdateItem = async () => {
    if (!newItemText) {
      swal("Error!", `${itemName.charAt(0).toUpperCase() + itemName.slice(1)} text cannot be empty.`, "error");
      return;
    }
  
    try {
      await axios.put(`http://localhost:5000/${apiEndpoint}/${selectedItem.id}`, {
        [itemName]: newItemText
      });
      swal("Updated!", `${itemName.charAt(0).toUpperCase() + itemName.slice(1)} updated successfully!`, "success");
      setModalIsOpen(false);
      fetchItems();
    } catch (error) {
      console.error(`Error updating ${itemName}:`, error);
      swal("Error!", `Failed to update ${itemName}.`, "error");
    }
  };
  
  const handleDeleteClick = async (itemText) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: `Once deleted, you will not be able to recover this ${itemName}!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  
    if (willDelete) {
      try {
        await axios.delete(`http://localhost:5000/${apiEndpoint}`, {
          params: { [itemName]: encodeURIComponent(itemText) }
        });
        swal("Deleted!", `${itemName.charAt(0).toUpperCase() + itemName.slice(1)} deleted successfully!`, "success");
        fetchItems();
      } catch (error) {
        console.error(`Error deleting ${itemName}:`, error);
        swal("Error!", `Failed to delete ${itemName}.`, "error");
      }
    } else {
      swal("Cancelled", `${itemName.charAt(0).toUpperCase() + itemName.slice(1)} deletion cancelled`, "info");
    }
  };
  
  return (
    <div className="container">
      <h2 className="header">{itemName.charAt(0).toUpperCase() + itemName.slice(1)} Management</h2>
      <div className='table-responsive'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>ID</th>
              <th>{itemName.charAt(0).toUpperCase() + itemName.slice(1)}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item[itemName]}</td>
                <td>
                  <button onClick={() => handleEditClick(item)} className='btn-edit'>Edit</button>
                  <button onClick={() => handleDeleteClick(item[itemName])} className='btn-delete'>Delete</button> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Edit {itemName.charAt(0).toUpperCase() + itemName.slice(1)}</h2>
        <input
          type="text"
          value={newItemText}
          onChange={(e) => setNewItemText(e.target.value)}
          className="modal-input"
        />
        <button onClick={handleUpdateItem} className="btn-update">Update {itemName.charAt(0).toUpperCase() + itemName.slice(1)}</button>
      </Modal>
    </div>
  );
}

export default Managecatop2;
